import useAuth from "src/hooks/useAuth";
import { PATH_USER } from "src/routes/paths";
import { getIconURI } from "../admin/NavConfig";

const ICONS = {
  blog: getIconURI("ic_blog"),
  cart: getIconURI("ic_cart"),
  chat: getIconURI("ic_chat"),
  mail: getIconURI("ic_mail"),
  user: getIconURI("ic_user"),
  kanban: getIconURI("ic_kanban"),
  banking: getIconURI("ic_banking"),
  booking: getIconURI("ic_booking"),
  invoice: getIconURI("ic_invoice"),
  calendar: getIconURI("ic_calendar"),
  ecommerce: getIconURI("ic_ecommerce"),
  analytics: getIconURI("ic_analytics"),
  dashboard: getIconURI("ic_dashboard"),
  setting: getIconURI("ic_settings"),
  online_store: getIconURI("ic_store"),
  my_subscription: getIconURI("ic_user_subscription"),
  business_builder: getIconURI("ic_bb"),
  profile: getIconURI("ic_profile"),
  help_center: getIconURI("ic_helpcenter"),
  report: getIconURI("ic_report"),
  investor: getIconURI("ic_investor"),
  reward: getIconURI("ic_reward"),
  reward: getIconURI("ic_team"),

  bonus: getIconURI("ic_bonus"),
};

/**
 * This build the navbar fo r the user side, each index will be a section on the side panel.
 * The object takes in the following keys:
 *  - subheader : for title of the section
 *  - items     : for taking in nav items
 *      - title : string
 *      - path  : link
 *      - icon  : icon
 *      - children: items[]
 */

const getChildren = (is100, is250, is500, is1000) => {
  let routes = [
    { title: "primaryPool", path: PATH_USER.genealogy.binary },
    // { title: "Sponsor", path: PATH_USER.genealogy.sponsor },
  ];

  if (is100) {
    routes = [
      ...routes,
      { title: "pool100", path: PATH_USER.genealogy.pool100 },
    ];
  }

  if (is250) {
    routes = [
      ...routes,
      { title: "pool250", path: PATH_USER.genealogy.pool250 },
    ];
  }
  if (is500) {
    routes = [
      ...routes,
      { title: "pool500", path: PATH_USER.genealogy.pool500 },
    ];
  }
  if (is1000) {
    routes = [
      ...routes,
      { title: "pool1000", path: PATH_USER.genealogy.pool1000 },
    ];
  }

  return routes;
};

const getReport = (director) => {
  let routes = [
    { title: "Pool ", path: PATH_USER.my_orders.root },
    { title: "revenue", path: PATH_USER.reports.incomeReport },
    {
      title: "referral",
      path: PATH_USER.reports.referralReport,
    },
    {
      title: "withdrawal",
      path: PATH_USER.reports.withdrawalReport,
    },
  ];

  if (director) {
    routes = [
      { title: "revenue", path: PATH_USER.reports.incomeReport },
      {
        title: "withdrawal",
        path: PATH_USER.reports.withdrawalReport,
      },
    ];
  }

  return routes;
};

const useNavConfig = () => {
  const { user } = useAuth();

  return [
    {
      items: [
        {
          is_stake: false,
          is_partner: false,
          is_purchased: true,
          is_director: true,
          is_userType: false,
          title: "Dashboard",
          path: PATH_USER.user_dashboard,
          icon: ICONS.dashboard,
        },
        {
          is_stake: false,
          is_partner: false,
          is_purchased: false,
          is_director: true,
          is_sales: true,
          is_userType: false,
          title: "Members",
          path: PATH_USER.investor,
          icon: ICONS.investor,
        },

        // {
        //   is_director: false,
        //   title: "joinTelegram",
        //   path: "https://web.telegram.org/",
        //   icon: ICONS.chat,
        // },

        {
          is_stake: false,
          is_partner: false,
          is_purchased: true,
          is_director: false,
          is_userType: true,
          is_sales: false,
          title: "Purchase",
          path: PATH_USER.invest.root,
          icon: ICONS.online_store,
          children: [
            {
              is_stake: true,
              is_purchased: false,
              is_userType: false,
              title: "Stake",
              path: PATH_USER.invest.stake,
            },
            // {
            //   is_stake: false,
            //   title: "Gift Coupon",
            //   path: PATH_USER.invest.gift,
            // },
            {
              is_purchased: false,
              title: "My_Orders",
              path: PATH_USER.invest.myOrders.root,
            },
            {
              is_purchased: true,
              title: "Pending Payments",
              path: PATH_USER.invest.pending_payments.root,
            },
          ],
        },
        // {
        //   is_director: false,
        //   title: "poolUpgrade",
        //   path: PATH_USER.onlineStore.poolupgrade,
        //   icon: ICONS.online_store,
        //   // children: [
        //   //   // {
        //   //   //   title: "product",
        //   //   //   path: PATH_USER.onlineStore.productSubscription.root,
        //   //   // },
        //   //   {
        //   //     title: "Pool Upgrade",
        //   //     path: PATH_USER.onlineStore.poolupgrade,
        //   //   },
        //   //   { title: "myOrders", path: PATH_USER.my_orders.root },
        //   // ],
        // },
        // {
        //   is_director: false,
        //   title: "mySubscriptions",
        //   icon: ICONS.my_subscription,
        //   path: PATH_USER.subscriptions.root,
        // },
        // {
        //   is_director: false,
        //   title: "genealogy",
        //   icon: ICONS.kanban,
        //   path: PATH_USER.genealogy.root,
        //   children: [
        //     {
        //       title: "poolTree",
        //       path: PATH_USER.genealogy.binary,
        //       // [
        //       //   { title: "primary pool", path: PATH_USER.genealogy.binary },
        //       //   { title: "pool 100", path: PATH_USER.genealogy.pool100 },
        //       //   { title: "pool 250", path: PATH_USER.genealogy.pool250 },
        //       //   { title: "pool 500", path: PATH_USER.genealogy.pool500 },
        //       //   { title: "pool 1000", path: PATH_USER.genealogy.pool1000 },

        //       //   // {
        //       //   //   title: "affiliateDashboard",
        //       //   //   path: PATH_USER.genealogy.affiliate,
        //       //   // },
        //       //   // {
        //       //   //   title: "binaryLegSettings",
        //       //   //   path: PATH_USER.genealogy.binaryLeg,
        //       //   // },
        //       // ],
        //     },
        //     { title: "sponsor", path: PATH_USER.genealogy.sponsor },
        //   ],
        // },
        {
          is_partner: false,
          is_purchased: false,
          is_sales: false,
          is_userType: false,
          is_director: true,
          title: "financial",
          path: PATH_USER.financial.root,
          icon: ICONS.ecommerce,
          children: [
            {
              is_purchased: false,
              is_director: true,
              title: "Finance Report",
              path: PATH_USER.financial.eWallet,
            },
            // {
            //   title: "depositWallet",
            //   path: PATH_USER.financial.depositWallet,
            // },
            // {
            //   is_purchased: false,
            //   is_director: true,
            //   title: "fundTransfer",
            //   path: PATH_USER.financial.fundsTransfer,
            // },
            // {
            //   is_purchased: false,
            //   is_director: true,
            //   title: "Amount Transfer",
            //   path: PATH_USER.financial.amountTransfer,
            // },
            {
              is_purchased: false,
              is_director: true,
              title: "RequestWithdrawal",
              path: PATH_USER.financial.payout,
            },
            {
              is_purchased: false,
              is_director: false,
              title: "Investment Report",
              path: PATH_USER.financial.stakeReport.root,
            },

            // {
            //   is_purchased: false,
            //   is_director: false,
            //   title: "Gift Coupon Report",
            //   path: PATH_USER.financial.giftCoupon,
            // },
            // {
            //   is_director: false,
            //   title: "GiftCodeReport",
            //   path: PATH_USER.financial.gift.root,
            //   icon: ICONS.analytics,
            // },

            ,
          ],
        },
        // {
        //   is_partner: false,
        //   is_purchased: false,
        //   is_director: true,
        //   is_sales: true,
        //   is_userType: false,
        //   title: "Bitzclub Reward",
        //   path: PATH_USER.reward,
        //   icon: ICONS.bonus,
        // },
        {
          is_partner: true,
          is_purchased: false,
          is_director: true,
          is_sales: true,
          is_userType: false,
          title: "Team",
          path: PATH_USER.team,
          icon: ICONS.reward,
        },
        // {
        //   is_director: true,
        //   title: "businessBuilder",
        //   icon: ICONS.business_builder,
        //   path: PATH_USER.business_builder.root,
        //   children: [
        //     {
        //       title: "subscriptions",
        //       path: PATH_USER.business_builder.subscriptions,
        //     },
        //     {
        //       title: "materials",
        //       path: PATH_USER.business_builder.materials.root,
        //     },
        //   ],
        // },
        // {
        //   is_director: true,
        //   title: "myProfile",
        //   icon: ICONS.profile,
        //   path: PATH_USER.profile.root,
        // },
        // {
        //   is_director: true,
        //   title: "blogs",
        //   icon: ICONS.blog,
        //   path: PATH_USER.blogs.root,
        // },
        {
          is_partner: false,
          is_purchased: false,
          is_sales: false,
          is_userType: false,
          is_director: true,
          title: "helpCenter",
          path: PATH_USER.helpCenter.root,
          icon: ICONS.help_center,
          children: [
            // {
            //   is_director: true,
            //   title: "faqs",
            //   path: PATH_USER.helpCenter.faq.root,
            // },
            // {
            //   title: "knowledgeBase",
            //   path: PATH_USER.helpCenter.knowledgeBase.root,
            // },
            // {
            //   title: "emails",
            //   path: PATH_USER.helpCenter.mails.all,
            // },
            {
              is_purchased: false,
              is_sales: false,
              is_director: true,
              title: "createTicket",
              path: PATH_USER.helpCenter.createTicket.contactSupport,
            },
          ],
        },
        // {
        //   is_director: true,
        //   title: "reports",
        //   path: PATH_USER.reports.root,
        //   icon: ICONS.report,
        //   children: getReport(user?.is_director === 1),
        //   // children: [
        //   //   { title: "Pool ", path: PATH_USER.my_orders.root },
        //   //   { title: "revenue", path: PATH_USER.reports.incomeReport },
        //   //   {
        //   //     title: "referral",
        //   //     path: PATH_USER.reports.referralReport,
        //   //   },
        //   //   {
        //   //     title: "withdrawal",
        //   //     path: PATH_USER.reports.withdrawalReport,
        //   //   },
        //   // ],
        // },

        // {
        //   title: "Voucher List",
        //   path: PATH_USER.voucher.root,
        //   icon: ICONS.analytics,
        // },
      ],
    },
  ];
};

export default useNavConfig;

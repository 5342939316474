import { Grid } from "@mui/material";
import Cards from "./cards";
import i18n from "src/locales/i18n";
import useAuth from "src/hooks/useAuth";

const Summary = ({ summary }) => {
  const { user } = useAuth();
  const summaryCard = [
    {
      isInvestor: true,
      title: "Stake Ewallet",
      icon: "solar:money-bag-bold",
      color: "#999966",
      key: "ewallet_stake",
    },
    {
      isInvestor: true,
      title: "Stake Exchange",
      icon: "fluent:wallet-48-regular",
      color: "#993366",
      key: "coinpayment_stake",
    },
    {
      isInvestor: true,
      title: "Customer Incentive",
      icon: "grommet-icons:stakeholder",
      color: "#d279a6",
      key: "incentive",
    },
    {
      isInvestor: true,
      title: "Surrender Stake",
      icon: "carbon:purchase",
      color: "#993366",
      key: "surrender_stake",
    },
    {
      isInvestor: true,
      title: "TransferIn",
      icon: "tabler:transfer-in",
      color: "#999966",
      key: "fund_transfer_credit",
    },
    {
      isInvestor: true,
      title: "TransferOut",
      icon: "tabler:transfer-out",
      color: "#6699ff",
      key: "fund_transfer_debit",
    },

    {
      isInvestor: true,
      title: "Withdrawal",
      icon: "ri:direction-fill",
      color: "#d279a6",
      key: "total_payout",
    },
    {
      isInvestor: true,
      title: "AvailableBalance",
      icon: "arcticons:priceconverter",
      color: "#d279a6",
      key: "balance",
    },
    {
      isInvestor: false,
      title: "Partner Incentive",
      icon: "grommet-icons:stakeholder",
      color: "#999966",
      key: "incentive",
    },
    {
      isInvestor: false,
      title: "Referral Bonus",
      icon: "arcticons:priceconverter",
      color: "#d279a6",
      key: "referral_bonus",
    },
    {
      isInvestor: false,
      title: "AvailableBalance",
      icon: "carbon:purchase",
      color: "#6699ff",
      key: "balance",
    },
    {
      isInvestor: false,
      title: "TransferOut",
      icon: "tabler:transfer-out",
      color: "#6699ff",
      key: "fund_transfer_debit",
    },
    {
      isInvestor: false,
      title: "TransferIn",
      icon: "tabler:transfer-in",
      color: "#999966",
      key: "fund_transfer_credit",
    },
    {
      isInvestor: false,
      title: "Withdrawal",
      icon: "ri:direction-fill",
      color: "#d279a6",
      key: "total_payout",
    },
    {
      isInvestor: false,
      title: "Reward",
      icon: "fluent:reward-12-filled",
      color: "#a06025",
      key: "bitz_club_reward",
    },
    {
      isInvestor: true,
      title: "Ewallet Purchase",
      icon: "bx:purchase-tag",
      color: "#999966",
      key: "ewallet_purchase",
    },
    {
      isInvestor: false,
      title: "Ewallet Purchase",
      icon: "bx:purchase-tag",
      color: "#999966",
      key: "ewallet_purchase",
    },
  ];

  return (
    <>
      {summaryCard
        .filter((card) => {
          if (user.user_type === "investor") {
            return card.isInvestor;
          }
          return !card.isInvestor;
        })
        .map((props) => (
          <Grid
            item
            xs={12}
            sm={5}
            md={user.user_type === "investor" ? 3 : 3}
            key={props.key}
          >
            <Cards {...props} total={summary[props.key]} />
          </Grid>
        ))}
    </>
  );
};

export default Summary;

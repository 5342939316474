import { PATH_DASHBOARD } from "src/routes/paths";
export const getIconURI = (name) => `/icons/${name}.svg`;

const ICONS = {
  blog: getIconURI("ic_blog"),
  cart: getIconURI("ic_cart"),
  chat: getIconURI("ic_chat"),
  mail: getIconURI("ic_mail"),
  user: getIconURI("ic_user"),
  kanban: getIconURI("ic_kanban"),
  banking: getIconURI("ic_banking"),
  booking: getIconURI("ic_booking"),
  invoice: getIconURI("ic_invoice"),
  calendar: getIconURI("ic_calendar"),
  ecommerce: getIconURI("ic_ecommerce"),
  analytics: getIconURI("ic_analytics"),
  dashboard: getIconURI("ic_dashboard"),
  settings: getIconURI("ic_settings"),
  tools: getIconURI("ic_tools"),
  member_management: getIconURI("ic_member_management"),
  subadmin: getIconURI("ic_subadmin"),
  holdingtank: getIconURI("ic_holdingtank"),
  store: getIconURI("ic_store"),
  user_subscription: getIconURI("ic_user_subscription"),
  report: getIconURI("ic_report"),
  directors: getIconURI("ic_persons"),
  register: getIconURI("ic_register"),
  team: getIconURI("ic_team"),
  addUser: getIconURI("ic_addRegister"),
  gift: getIconURI("ic_gift"),
};

const navConfig = [
  {
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.dashboard.business,
        icon: ICONS.dashboard,
        // children: [
        //   {
        //     title: "business",
        //     path: PATH_DASHBOARD.dashboard.business,
        //   },
        //   {
        //     title: "network",
        //     path: PATH_DASHBOARD.dashboard.network,
        //   },
        // ],
      },
      {
        title: "Register",
        path: PATH_DASHBOARD.register.root,
        icon: ICONS.addUser,
      },

      // {
      //   title: "genealogy",
      //   path: PATH_DASHBOARD.genealogy.root,
      //   icon: ICONS.kanban,
      //   children: [
      //     // {
      //     //   title: "poolTree",
      //     //   path: PATH_DASHBOARD.genealogy.binary,
      //     //   // children: [
      //     //   //   {
      //     //   //     title: "primaryPool",
      //     //   //     path: PATH_DASHBOARD.genealogy.binary,
      //     //   //   },
      //     //   //   { title: "pool100", path: PATH_DASHBOARD.genealogy.pool100 },
      //     //   //   { title: "pool250", path: PATH_DASHBOARD.genealogy.pool250 },
      //     //   //   { title: "pool500", path: PATH_DASHBOARD.genealogy.pool500 },
      //     //   //   { title: "pool1000", path: PATH_DASHBOARD.genealogy.pool1000 },
      //     //   // ],
      //     // },
      //     // // {
      //     // //   title: "Rebirth Tree",
      //     // //   children: [
      //     // //     {
      //     // //       title: "Rebirth 100",
      //     // //       path: PATH_DASHBOARD.genealogy.rebirth100,
      //     // //     },
      //     // //     {
      //     // //       title: "Rebirth 250",
      //     // //       path: PATH_DASHBOARD.genealogy.rebirth250,
      //     // //     },
      //     // //     {
      //     // //       title: "Rebirth 500",
      //     // //       path: PATH_DASHBOARD.genealogy.rebirth500,
      //     // //     },
      //     // //     {
      //     // //       title: "Rebirth 1000",
      //     // //       path: PATH_DASHBOARD.genealogy.rebirth1000,
      //     // //     },
      //     // //   ],
      //     // // },
      //     // // {
      //     // //   title: "rebirthTree",
      //     // //   path: PATH_DASHBOARD.genealogy.rebirth100,
      //     // // },
      //     // {
      //     //   title: "sponsor",
      //     //   path: PATH_DASHBOARD.genealogy.sponsor,
      //     // },
      //     { title: "tree", path: PATH_DASHBOARD.genealogy.tree },
      //   ],
      // },
      {
        title: "financial",
        path: PATH_DASHBOARD.financial.root,
        icon: ICONS.ecommerce,
        children: [
          {
            title: "Finance Report",
            path: PATH_DASHBOARD.financial.ewallet,
          },

          // {
          //   title: "depositWallet",
          //   path: PATH_DASHBOARD.financial.deposit_wallet,
          // },
          // {
          //   title: "tradeFund",
          //   path: PATH_DASHBOARD.financial.fund_credits,
          // },
          {
            title: "payout",
            path: PATH_DASHBOARD.financial.payout,
          },
          {
            title: "Order Approval",
            path: PATH_DASHBOARD.financial.order_approval,
          },
        ],
      },
      // {
      //   title: "Gift Coupon",
      //   path: PATH_DASHBOARD.coupon_report.root,
      //   icon: ICONS.gift,
      // },
      {
        title: "Fund Allocation",
        path: PATH_DASHBOARD.allocateFund.root,
        icon: ICONS.register,
      },
      {
        title: "Team",
        path: PATH_DASHBOARD.team.root,
        icon: ICONS.team,
      },
      // {
      //   title: "directors",
      //   icon: ICONS.directors,
      //   path: PATH_DASHBOARD.directors.root,
      // },
      {
        title: "communication",
        path: PATH_DASHBOARD.communication.root,
        icon: ICONS.chat,
        children: [
          // {
          //   title: "blog",
          //   path: PATH_DASHBOARD.communication.blog,
          // },
          {
            title: "faqs",
            path: PATH_DASHBOARD.communication.com_faqs,
          },
          // {
          //   title: "emails",
          //   path: PATH_DASHBOARD.communication.mails,
          // },
          {
            title: "helpCenterr",
            path: PATH_DASHBOARD.communication.help_center,
          },
          // {
          //   title: "article",
          //   path: PATH_DASHBOARD.communication.com_article,
          // },
        ],
      },

      // {
      //   title: "tools",
      //   path: PATH_DASHBOARD.tools.root,
      //   icon: ICONS.tools,
      //   children: [
      //     {
      //       title: "documents",
      //       path: PATH_DASHBOARD.tools.documents,
      //     },
      //     { title: "videos", path: PATH_DASHBOARD.tools.videos },
      //   ],
      // },
      {
        title: "membersManagement",
        path: PATH_DASHBOARD.members.root,
        icon: ICONS.member_management,
        children: [
          {
            title: "Customers",
            path: PATH_DASHBOARD.members.investor,
          },
          {
            title: "Partners",
            path: PATH_DASHBOARD.members.network,
          },
          {
            title: "Senior Partners",
            path: PATH_DASHBOARD.members.seniorPartner,
          },
          {
            title: "Agency Partners",
            path: PATH_DASHBOARD.members.agency,
          },
          {
            title: "Executive Partners",
            path: PATH_DASHBOARD.members.executive_partner,
          },
          {
            title: "Sales Managers",
            path: PATH_DASHBOARD.members.sales_manager,
          },
          {
            title: "Regional Managers",
            path: PATH_DASHBOARD.members.regional_manager,
          },
          {
            title: "General Managers",
            path: PATH_DASHBOARD.members.general_manager,
          },

          // {
          //   title: "holdingTank",
          //   path: PATH_DASHBOARD.members.holdingTank,
          // },
        ],
      },

      // {
      //   title: "subAdmin",
      //   path: PATH_DASHBOARD.subAdmin.root,
      //   icon: ICONS.subadmin,
      //   children: [
      //     {
      //       title: "subAdmins",
      //       path: PATH_DASHBOARD.subAdmin.sub_admins,
      //     },
      //   ],
      // },

      // {
      //   title: "store",
      //   path: PATH_DASHBOARD.store.root,
      //   icon: ICONS.store,
      //   children: [
      //     {
      //       title: "products",
      //       path: PATH_DASHBOARD.store.products,
      //     },
      //     {
      //       title: "material",
      //       path: PATH_DASHBOARD.store.material,
      //     },
      //     { title: "events", path: PATH_DASHBOARD.store.events },
      //     {
      //       title: "Coupons",
      //       path: PATH_DASHBOARD.store.coupons,
      //     },
      //     {
      //       title: "userReviews",
      //       path: PATH_DASHBOARD.store.user_reviews,
      //     },

      //     {
      //       title: "assignSubscriptions",
      //       path: PATH_DASHBOARD.store.assign_subscriptions,
      //     },
      //     {
      //       title: "businessBuilderSubscriptionsSales",
      //       path: PATH_DASHBOARD.store.business_builder_subscriptions,
      //     },
      //   ],
      // },

      // {
      //   title: "userSubscriptions",
      //   icon: ICONS.user_subscription,
      //   path: PATH_DASHBOARD.activeSubscriptions.root,
      // },
      // {
      //   title: "statistics",
      //   icon: ICONS.analytics,
      //   path: PATH_DASHBOARD.statistics.root,
      // },
    ],
  },

  {
    subheader: "general",
    items: [
      {
        title: "settings",
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.settings,
        children: [
          {
            title: "brand",
            path: PATH_DASHBOARD.settings.brand,
          },
          // {
          //   title: "businessBuilder",
          //   path: PATH_DASHBOARD.settings.business,
          // },
          {
            title: "network",
            path: PATH_DASHBOARD.settings.network,
          },
          {
            title: "Withdrawal",
            path: PATH_DASHBOARD.settings.withdrawal,
          },
        ],
      },
      {
        title: "reports",
        path: PATH_DASHBOARD.report.root,
        icon: ICONS.report,
        children: [
          // {
          //   title: "Investment",
          //   path: PATH_DASHBOARD.report.poolReport,
          // },
          // {
          //   title: "director",
          //   path: PATH_DASHBOARD.report.director,
          // },
          {
            title: "Investment",
            path: PATH_DASHBOARD.report.stake,
          },
          {
            title: "Sale Partner",
            path: PATH_DASHBOARD.report.salePartner,
          },
          // {
          //   title: "rebirth",
          //   path: PATH_DASHBOARD.report.rebirth,
          // },
          // {
          //   title: "Profile",
          //   path: PATH_DASHBOARD.report.profilereport,
          // },
          // {
          //   title: "referralReport",
          //   path: PATH_DASHBOARD.report.referralReport,
          // },
          // {
          //   title: "builderSubscription",
          //   path: PATH_DASHBOARD.report.builder,
          // },
          // {
          //   title: "fundCredit",
          //   path: PATH_DASHBOARD.report.fund_credit,
          // },
          // {
          //   title: "joining",
          //   path: PATH_DASHBOARD.report.joining,
          // },
          {
            title: "Finance",
            path: PATH_DASHBOARD.report.member_income,
          },
          // {
          //   title: "roswallet",
          //   path: PATH_DASHBOARD.report.roi_wallet,
          // },
          {
            title: "withdrawal",
            path: PATH_DASHBOARD.report.withdrawal,
          },
          {
            title: "Trip",
            path: PATH_DASHBOARD.report.trip,
          },
          // {
          //   title: "Bitzclub Reward",
          //   path: PATH_DASHBOARD.report.reward,
          // },
          // {
          //   title: "reberth",
          //   path: PATH_DASHBOARD.report.usersRebirth,
          // },
          // {
          //   title: "Withdrawal",
          //   path: PATH_DASHBOARD.report.payout_report,
          // },

          // {
          //   title: "pointHistory",
          //   path: PATH_DASHBOARD.report.point_history,
          // },
          // {
          //   title: "sales",
          //   path: PATH_DASHBOARD.report.sales_report,
          // },
          // {
          //   title: "topEarners",
          //   path: PATH_DASHBOARD.report.top_earners,
          // },
        ],
      },

      // {
      //   title: "giftCode",
      //   path: PATH_DASHBOARD.voucher.root,
      //   icon: ICONS.analytics,
      // },
    ],
  },
];
export default navConfig;
